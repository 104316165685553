/* global $ */

// Activate a modal-preview's sibling modal window
$('.modal-preview').find('.image-grow, .readmore').on('click', function (e) {
  e.preventDefault()

  $(this).closest('.modal-preview').parent().children('.modal-wrapper').addClass('modal-active')
})

// Close the modal window when clicking the closicon or the modal overlay background
$('.modal-wrapper').on('click', function (e) {
  if ($(e.target).is('.modal-wrapper') || $(e.target).is('.closicon')) {
    e.preventDefault()

    $(this).closest('.modal-active').removeClass('modal-active')
  }
})
