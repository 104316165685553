/* global $ */

// Open share links in a modal window
// https://stackoverflow.com/questions/23309721/open-facebook-share-in-a-modal-pop-up-window-or-standard-pop-up-window/23309862#23309862
$('a.share').on('click', function (e) {
  e.preventDefault()
  const url = $(this).attr('href')
  window.open(url, 'shareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')
  return false
})
