/* global $ */

// Set the cookie expiration to a month from creation
const now = new Date()
const month = new Date(now.getTime() + (30 * 24 * 60 * 60 * 1000)) // Month in milliseconds
const expires = month.toUTCString()

$('.announcements .closicon').on('click', () => {
  $('.announcements').addClass('announcements-closed')
  document.cookie = `cisive_hide_announcement=1; path=/; expires=${expires}`
})

$('.announcements').addClass('announcements-active')
