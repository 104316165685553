/* global $ */

// Add classes to search-wrapper to make search bar usable
const activateSearch = (e) => {
  e.stopPropagation()
  const search = $('.search-wrapper')
  if (!search.hasClass('search-active')) {
    search.addClass('search-active')
    // Auto focus on search bar input
    search.find('.search-input input').focus()
  }
}

// Return search bar to initial state
const deactivateSearch = () => {
  const search = $('.search-wrapper')
  // If search is active but has nothing in it
  if (search.hasClass('search-active') && !$('.search-wrapper .search-input input').val().length) {
    // Remove active search classes
    search.removeClass('search-active')
    // Remove focus from input (may already happen with visibility in css)
    search.find('.search-input input').blur()
    return true
  }
}

// Activate search by clicking on the search icon
$('.search-wrapper').on('click', (e) => activateSearch(e))

// Activate search when tabbing to it
$('.search-wrapper input').on('focus', (e) => activateSearch(e))

// Deactivate search when clicking anywhere else
$(window).on('click', deactivateSearch)

$('.search-wrapper input[type=submit]').on('click', (e) => {
  const isDeactivated = deactivateSearch()
  // Check if deactivation happened and prevent the form from submitting
  if (isDeactivated) e.preventDefault()
})
