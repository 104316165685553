/* global $ */

const mobileWidth = 600

// Add a mobile nav class on click
$('header .navicon').on('click', () => {
  $('header').toggleClass('menu-active')
})

// // Remove default action on top-level main menu items and expand children instead
// // Hopefully the submenu duplicates the top-level link, or the user is gonna have a bad time
// $('header .menu-top > li.expanded').children('a, .nolink').on('click', function (e) {
//   // Only do this for mobile, cuz it breaks the main navigation when
//   // in desktop
//   if ($('.navicon').is(':visible')) {
//     e.preventDefault()
//     $(this).parent().toggleClass('submenu-expanded')
//   }
// })
