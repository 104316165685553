/* global $ */

$('.owl-carousel').owlCarousel({
  items: 1,
  center: true,
  nav: true,
  dots: true,
  responsiveRefreshRate: 50,
  itemElement: 'field-item',
  rewind: true,
  smartSpeed: 190,
  navText: [
    '<svg class="icon icon-arrow-left"><use xlink:href="/sites/all/themes/master/dist/images/sprite.svg#icon-arrow-left" /></svg>',
    '<svg class="icon icon-arrow-right"><use xlink:href="/sites/all/themes/master/dist/images/sprite.svg#icon-arrow-right" /></svg>'
  ]
})
