/* global $ */

// Get the admin menu height and offset the body and header so
// it's always visible
// if ($('body').hasClass('logged-in')) {
//   const headerOffset = () => {
//     // Unset offset when mobile admin menu is showing
//     let adminHeight = $('#admin-menu').height()
//     $('header').css('top', adminHeight)
//     $('body').css('padding-top', adminHeight)
//   }
//
//   // Add a timeout to give Drupal time to create the admin bar
//   setTimeout(headerOffset, 1000)
//
//   // Debouncing to prevent it from running on every resize event
//   // https://css-tricks.com/snippets/jquery/done-resizing-event
//   $(window).on('resize', (e) => {
//     let resizeTimer
//     clearTimeout(resizeTimer)
//     resizeTimer = setTimeout(() => { return headerOffset() }, 300)
//   })
// }
